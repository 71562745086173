<template>
  <div class="content-wrapper products-detail">
    <breadcrumb
      :name="goodsId ? 'productsDetail' : 'productsAddProducts'"
      style="margin-left: 10px"
    ></breadcrumb>
    <div class="content titleFormBox" style="font-weight: 900">
      <a-tabs default-active-key="1" @tabClick="linkTo">
        <a-tab-pane key="BasicInfo" :tab="$t(`基本信息`)" force-render></a-tab-pane>
        <a-tab-pane key="ProductInfo" :tab="$t(`商品信息`)" force-render></a-tab-pane>
        <a-tab-pane key="LogisticsInformation" :tab="$t(`物流信息`)" force-render></a-tab-pane>
        <a-tab-pane
          v-if="quickCreate && publishAttributes.length > 0"
          key="PublishInformation"
          :tab="$t(`发布信息`)"
          force-render
        ></a-tab-pane>
        <a-tab-pane
          v-if="quickCreate && publishAttributes.length > 0"
          key="CommitInformation"
          :tab="$t(`审核信息`)"
          force-render
        ></a-tab-pane>
      </a-tabs>
    </div>
    <!-- Main content -->
    <section class="content">
      <div class="goods-main-content" :style="{ height: mainHeight }">
        <div id="BasicInfo" :class="{ hasLog: hasLog }">
          <a-form-model :model="formData" v-bind="formLayout">
            <div class="formContentGoods">
              <div class="infoTitle">
                <i class="leftLine"></i>
                {{ $t('基本信息') }}
              </div>
              <a-row>
                <a-col :span="12">
                  <a-form-model-item :label="$t(`商品名称`)" required v-bind="formItemLayout">
                    <a-input
                      v-model="formData.goodsName"
                      :disabled="!is_edit"
                      @blur="confirmCreate('speed')"
                    ></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item :label="$t(`商品编码`)" v-bind="formItemLayout">
                    <a-input
                      v-model="formData.goodsCode"
                      @input="handleInput"
                      :disabled="goodsId && this.pageType !== 'copy'"
                    ></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item :label="$t(`商品简称`)" v-bind="formItemLayout">
                    <a-input v-model="formData.abbreviation" :disabled="!is_edit"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item :label="$t(`系列名称`)" v-bind="formItemLayout">
                    <a-input v-model="formData.collectionName" :disabled="!is_edit"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item :label="$t(`商品类型`)" v-bind="formItemLayout" required>
                    <a-select
                      v-model="formData.goodsType"
                      :disabled="!is_edit"
                      style="width: 100%"
                      @change="changeGoodsType"
                    >
                      <a-select-option
                        :value="key"
                        v-for="(item, key) in goodsTypeMapping"
                        :key="key"
                      >
                        {{ item }}
                      </a-select-option>
                    </a-select>
                    <a-popover>
                      <template slot="content">
                        <p>
                          {{ $t(`普通商品`) }}/{{ $t(`赠品`) }}：{{
                            $t(`需要仓库发货，实际拥有库存的商品/赠品`)
                          }}
                        </p>
                        <p>
                          {{ $t(`虚拟商品`) }}：{{
                            $t(`无需仓库发货，推仓时将自动过滤的商品，如权益、福利等`)
                          }}
                        </p>
                        <p>
                          {{ $t(`注：订单中的虚拟商品和赠品支持删除，普通商品无法删除`) }}
                        </p>
                      </template>
                      <a-icon class="goodsTips" type="question-circle"></a-icon>
                    </a-popover>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item :label="$t(`商品类目`)" required v-bind="formItemLayout">
                    <CategoryList
                      :canNotAdd="true"
                      :disabled="!is_edit"
                      :defaultValue="formData.categoryId"
                      @handleCategoryChange="handleCategoryChange"
                      @createCategory="createCategory"
                      @changeList="confirmCreate"
                    />
                  </a-form-model-item>
                </a-col>
                <!-- 品牌 -->
                <a-col :span="12">
                  <a-form-model-item :label="$t(`品牌`)" v-bind="formItemLayout">
                    <a-select
                      :disabled="!is_edit"
                      default-value="lucy"
                      @change="handleChange"
                      v-model="formData.brandName"
                    >
                      <a-select-option
                        v-for="item in brandNameOptions"
                        :value="item.brandName"
                        :key="item.brandId"
                      >
                        {{ item.brandName }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12" v-if="isStore">
                  <a-form-model-item :label="$t(`是否需要客制`)" v-bind="formItemLayout" required>
                    <a-select
                      v-model="formData.needCustomize"
                      :disabled="!is_edit || formData.goodsType === 'RAW_MATERIAL'"
                      style="width: 100%"
                    >
                      <a-select-option :value="0">{{ $t('不需要') }}</a-select-option>
                      <a-select-option :value="1">{{ $t('需要') }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12" v-if="isStore">
                  <a-form-model-item :label="$t(`销售类型`)" v-bind="formItemLayout" required>
                    <a-select v-model="formData.saleType" :disabled="!is_edit" style="width: 100%">
                      <a-select-option
                        :value="key"
                        v-for="(item, key) in saleTypeMapping"
                        :key="key"
                      >
                        {{ item }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12" v-if="isStore">
                  <a-form-model-item :label="$t(`存储方式`)" v-bind="formItemLayout" required>
                    <a-select
                      v-model="formData.storageCondition"
                      :disabled="!is_edit"
                      style="width: 100%"
                    >
                      <a-select-option value="CHANG_WEN">
                        {{ $t('常温') }}
                      </a-select-option>
                      <a-select-option value="LENG_CANG">
                        {{ $t('冷藏') }}
                      </a-select-option>
                      <a-select-option value="LENG_DONG">{{ $t('冷冻') }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12" v-if="hasFMSApp">
                  <a-form-model-item :label="$t(`业务单元`)" v-bind="formItemLayout" required>
                    <SelectBU
                      :code.sync="formData.businessUnitCode"
                      :name.sync="formData.businessUnitName"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <template v-if="quickCreate">
                <a-form-model-item :label="$t(`类目`)" v-if="category_text">
                  <span class="text-info">{{ category_text }}</span>
                </a-form-model-item>
                <a-form-model-item :label="$t(`店铺`)">
                  <a-col :span="12">
                    <SelectShop :code.sync="publishData.shopCode" @selected="selectedShop" />
                  </a-col>
                </a-form-model-item>
              </template>
              <a-form-model-item :label="$t(`商品图`)" :required="!isDYTM">
                <div class="image-list">
                  <UploadImage
                    :needSizeCheck="false"
                    :disabled="!is_edit"
                    :multiple="8"
                    :imageList.sync="uploadImageUrlList"
                    @changeList="confirmCreate"
                  />
                  <!-- <div class="tips" v-if="!isDYTM">
                    {{ $t('商品图必填，尺寸在500×500到2000×2000px之间') }}
                  </div> -->
                </div>
                <!-- 新增业务组 -->
              </a-form-model-item>
              <!-- <a-form-model-item :label="$t(`上传视频`)">
                <div class="image-list">
                  <div style="display: flex">
                    <div v-if="videoUrl" class="videolist">
                      <video
                        class="video"
                        style="width: 102px; height: 102px"
                        :src="videoUrl"
                        alt=""
                      />
                      <span @click="playVideoHandle"><i class="fa-solid fa-circle-play"></i></span>
                    </div>
                    <a-upload
                      name="file"
                      list-type="picture-card"
                      class="avatar-uploader"
                      accept="video/mp4"
                      :show-upload-list="false"
                      :action="videoUpload"
                      :headers="{ 'Authorization': token }"
                      :before-upload="beforeVideoUpload"
                      @change="handleVideoChange"
                    >
                      <a-spin :spinning="loading">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">Upload</div>
                      </a-spin>
                    </a-upload>
                  </div>
                  <div class="tips">{{ $t('大小20M，格式MP4') }}</div>
                </div>
              </a-form-model-item> -->
              <a-row>
                <a-col :span="12">
                  <a-form-model-item :label="$t(`税率`)" v-bind="formItemLayout">
                    <a-input
                      v-model="formData.taxRate"
                      :disabled="!is_edit"
                      @blur="confirmCreate('speed')"
                      addon-after="%"
                    ></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item
                    :label="$t(`保质期`)"
                    v-bind="formItemLayout"
                    :required="isStore"
                  >
                    <a-input
                      v-model="formData.shelfLife"
                      :disabled="!is_edit"
                      @blur="confirmCreate('speed')"
                    >
                      <a-select
                        slot="addonAfter"
                        v-model="formData.shelfLifeUnit"
                        style="width: 60px"
                      >
                        <a-select-option :value="$t(`天`)">{{ $t(`天`) }}</a-select-option>
                        <a-select-option :value="$t('月')">{{ $t('月') }}</a-select-option>
                      </a-select>
                    </a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-form-model-item :label="$t(`业务组`)" required>
                <a-checkbox-group
                  :disabled="!is_edit"
                  :options="plainOptions"
                  @change="onChange"
                  v-model="buDefaultValue"
                />
              </a-form-model-item>
            </div>
            <div class="formContentGoods" v-if="isStore">
              <div class="infoTitle" id="ChangeInfo">
                <i class="leftLine"></i>
                {{ $t('变更信息') }}
              </div>
              <a-row>
                <a-col :span="12">
                  <a-form-model-item :label="$t(`变更销售类型`)" v-bind="formItemLayout">
                    <a-select
                      v-model="formData.nextSaleType"
                      :disabled="!is_edit"
                      style="width: 100%"
                    >
                      <a-select-option value="FRESHLY_MADE_AND_SOLD">
                        {{ $t('现制现售') }}
                      </a-select-option>
                      <a-select-option value="STOCK_UP_FOR_SALE">
                        {{ $t('备货销售') }}
                      </a-select-option>
                      <a-select-option value="PURCHASE_SALE">{{ $t('采购销售') }}</a-select-option>
                      <a-select-option value="ONLY_FOR_PRODUCTION">
                        {{ $t('生产专用') }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item :label="$t(`销售类型变更时间`)" v-bind="formItemLayout">
                    <DateRangePicker
                      id="nextSaleTime"
                      ref="nextSaleTime"
                      :needhms="true"
                      :disabled="!is_edit"
                      :singleDatePicker="true"
                      :startTime="formData.nextSaleTypeEffectiveDate"
                      @get_date="getNextSaleTime"
                    ></DateRangePicker>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
            <div class="formContentGoods">
              <div class="infoTitle" id="ProductInfo">
                <i class="leftLine"></i>
                {{ $t('商品信息') }}
              </div>
              <a-form-model-item :label="$t(`sku信息`)" required>
                <SkuSetting
                  ref="skuChild"
                  :formData="formData"
                  :pageType="pageType"
                  :is_edit="is_edit"
                  :styleTypeList="styleTypeList"
                  :defaultTableData="formData.skuList"
                  :speed="speed.isTrue"
                  :unitList.sync="unitList"
                  @changeList="confirmCreate"
                />
              </a-form-model-item>
            </div>
            <div class="formContentGoods">
              <div class="infoTitle" id="LogisticsInformation">
                <i class="leftLine"></i>
                {{ $t('物流信息') }}
              </div>
              <a-form-model-item :label="$t(`包裹重量`) + '(kg)'" :required="!isStore">
                <a-col :span="12">
                  <a-input
                    addon-after="kg"
                    @blur="confirmCreate('speed')"
                    v-model="formData.packageWeight"
                    :disabled="!is_edit"
                  ></a-input>
                </a-col>
              </a-form-model-item>
              <a-form-model-item :label="$t(`包装尺寸`) + '(cm)'" :required="!isStore">
                <a-row :gutter="[8, 8]">
                  <a-col :span="6">
                    <a-input
                      addon-after="cm"
                      type="number"
                      @blur="confirmCreate('speed')"
                      v-model="formData.packageLength"
                      :disabled="!is_edit"
                    ></a-input>
                  </a-col>
                  <a-col :span="6">
                    <a-input
                      addon-after="cm"
                      type="number"
                      @blur="confirmCreate('speed')"
                      v-model="formData.packageWidth"
                      :disabled="!is_edit"
                    ></a-input>
                  </a-col>
                  <a-col :span="6">
                    <a-input
                      addon-after="cm"
                      type="number"
                      @blur="confirmCreate('speed')"
                      v-model="formData.packageHeight"
                      :disabled="!is_edit"
                    ></a-input>
                  </a-col>
                </a-row>
              </a-form-model-item>
              <a-form-model-item :label="$t(`包裹信息`)">
                <a-col :span="18">
                  <a-input v-model="formData.packageContent" :disabled="!is_edit"></a-input>
                </a-col>
              </a-form-model-item>
            </div>

            <template v-if="quickCreate && publishAttributes.length > 0">
              <div class="formContentGoods">
                <div class="infoTitle" id="PublishInformation">
                  <i class="leftLine"></i>
                  {{ $t('发布信息') }}
                </div>
                <!-- <h5 class="title">{{ $t("发布信息") }}</h5> -->
                <div class="modal-attr-list">
                  <a-form-model-item
                    class="attr-item"
                    v-for="item in publishAttributes"
                    :key="item.name"
                    :required="!!item.isMandatory"
                  >
                    <template slot="label">
                      <span class="key-icon" v-if="item.advanced.isKeyProp">key</span>
                      <span>{{ item.label }}</span>
                    </template>
                    <a-col :span="18">
                      <PublishModalInput
                        :disabled="!is_edit"
                        :options="item.options"
                        :type="item.inputType"
                        :value.sync="publishData.goodsAttributes[item.name]"
                        @changeList="confirmCreate"
                      />
                    </a-col>
                  </a-form-model-item>
                </div>
              </div>
              <div class="formContentGoods">
                <!-- <h5 class="title">{{ $t("审核信息") }}</h5> -->
                <div class="infoTitle" id="CommitInformation">
                  <i class="leftLine"></i>
                  {{ $t('审核信息') }}
                </div>
                <a-form-model-item :label="$t(`开始时间`)" required>
                  <a-col :span="12">
                    <DateRangePicker
                      id="onSaleTime"
                      ref="onSaleTime"
                      :disabled="!is_edit"
                      :needhms="true"
                      :singleDatePicker="true"
                      :startTime="commitData.onSaleTime"
                      @get_date="getOnSaleTime"
                      @changeList="confirmCreate"
                    />
                  </a-col>
                </a-form-model-item>
                <a-form-model-item :label="$t(`结束时间`)">
                  <a-col :span="12">
                    <DateRangePicker
                      id="offSaleTime"
                      ref="offSaleTime"
                      :disabled="!is_edit"
                      :needhms="true"
                      :singleDatePicker="true"
                      :startTime="commitData.offSaleTime"
                      @get_date="getOffSaleTime"
                    />
                  </a-col>
                </a-form-model-item>
              </div>
            </template>
          </a-form-model>
        </div>
        <div class="rightBox" v-if="hasLog">
          <p class="formContentGoods" style="margin: 0; font-size: 14px">
            {{ $t('操作日志') }}
          </p>
          <a-timeline class="formContentGoods">
            <a-timeline-item v-for="(item, index) in operateLogList" :key="index">
              <p
                style="margin-bottom: 0px; color: #333333"
                v-html="item.operate.replaceAll('\n', '<br/>')"
              />
              <p style="margin: 0; color: #999999">
                {{ item.createdUser }}&nbsp;{{ item.createdAt }}
              </p>
            </a-timeline-item>
          </a-timeline>
        </div>
      </div>
    </section>
    <div class="content bottomFormBox">
      <a-row type="flex" justify="center">
        <a-col :span="10">
          <a-row style="margin-top: 4px">
            <!-- <a-col :span="8">当前填写完成度{{ speed.number }}%</a-col> -->
            <a-col :span="18" :offset="2">
              <a-progress v-if="is_edit" :percent="speed.number" />
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="14">
          <a-row type="flex" justify="start" :gutter="16">
            <a-col v-if="goodsId && !is_edit">
              <a-button @click="is_edit = true" type="primary">
                {{ $t('编辑') }}
              </a-button>
            </a-col>
            <a-col>
              <a-button @click="goToList">
                {{ $t('返回') }}
              </a-button>
            </a-col>
            <a-col v-if="pageType === 'add' && is_edit">
              <a-button type="primary" @click="saveDraft">{{ $t('存草稿') }}</a-button>
            </a-col>
            <a-col v-if="is_edit">
              <a-button type="primary" @click="confirmCreate">{{ $t('保存') }}</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <a-upload
      class="hide"
      :action="lazadaImgReqUrl"
      :headers="{ Authorization: token }"
      :multiple="true"
      accept="image/jpg,image/jpeg,image/png"
      list-type="picture-card"
      :data="{
        type: 'image',
        shopCode: this.publishData.shopCode,
        platform: this.publishData.platform,
      }"
      :before-upload="beforeUpload"
      @change="handleUploadChange"
    >
      <div class="ant-upload-text" id="Upload">Upload</div>
    </a-upload>
    <a-modal v-model="visibleVideo" :footer="null">
      <video :src="videoUrl" controls="controls" autoplay width="500px" height="500px" />
    </a-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import http from '@service/axios'
import api from '@service/api'
import SkuSetting from '@component/skuSetting'
import CategoryList from '@component/categoryList'
import UploadImage from '@component/uploadImage'
import SelectShop from '@component/selectShop'
import PublishModalInput from '@component/publishModalInput'
import DateRangePicker from '@component/dateRangePicker'
import SelectBU from '@component/selectBU'
import { Modal } from 'ant-design-vue'
import { getCookie } from '@/common/cookie'
import debounce from '@common/debounce'
import deepClone from '@common/deepClone'
// 工具栏配置
// const toolbarOptions = [
//   ['bold', 'italic', 'underline', 'strike'], // toggled buttons
//   ['blockquote', 'code-block'],
//   [{ header: 1 }, { header: 2 }], // custom button values
//   [{ list: 'ordered' }, { list: 'bullet' }],
//   [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
//   [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
//   [{ direction: 'rtl' }], // text direction
//   [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
//   [{ header: [1, 2, 3, 4, 5, 6, false] }],
//   [{ color: [] }, { background: [] }], // dropdown with defaults from theme
//   [{ font: [] }],
//   [{ align: [] }],
//   ['link', 'image', 'video'],
//   ['clean'], // remove formatting button
// ]

export default {
  name: 'productsDetail',
  activated() {
    this.clearFormData()
    this.goodsId = this.$route.query.id
    this.pageType = this.$route.query.type || 'add'
    this.speed.number = [('copy', 'edit')].indexOf(this.pageType) > -1 ? 100 : 0
    this.quickCreate =
      (this.$route.query.quickCreate && JSON.parse(this.$route.query.quickCreate)) || false
    let from_create = localStorage.getItem('from_create')
    this.getbrandList()
    this.shelfLifeUnit = this.formData.shelfLifeUnit || this.$t('天')
    let userSession = this.$store.state.userSession
    let buList = userSession.buList
    this.plainOptions = buList.map((item) => ({
      value: String(item.id),
      label: item.name,
    }))
    if (from_create) {
      this.$refs.skuChild.removeSku()
      localStorage.removeItem('from_create')
    }
    if (this.goodsId) {
      this.$refs.skuChild.removeSku()
      this.queryGoodsDetail()
      this.formData.goodsId = this.pageType === 'copy' ? '' : this.goodsId
      this.is_edit = this.pageType === 'copy' ? true : JSON.parse(this.$route.query.is_edit)
      this.barName = this.pageType === 'copy' ? 'copyProduct' : 'editProduct'
      if (this.pageType === 'copy') {
        this.formData.goodsCode = ''
      }
    } else {
      this.is_edit = true
      this.buDefaultValue = [String(this.$store.getters.partyId)]
      this.barName = 'addProduct'
      this.loadDraft()
    }

    if (this.isStore) {
      this.formData.packageWeight = '1'
      this.formData.packageLength = '1'
      this.formData.packageWidth = '1'
      this.formData.packageHeight = '1'
      this.mathSum('packageWeight', 'up')
      this.mathSum('packageLength', 'up')
    }
  },
  components: {
    SkuSetting,
    CategoryList,
    UploadImage,
    SelectShop,
    PublishModalInput,
    DateRangePicker,
    SelectBU,
  },
  provide() {
    return {
      page: 'productDetail',
    }
  },
  computed: {
    ...mapState(['goodsTypeMapping', 'saleTypeMapping']),
    token() {
      return getCookie('d_session_id')
    },
    lazadaImgReqUrl() {
      let cpid = this.$store.getters.partyId
      return api.uploadLocal + '?cpid=' + cpid
    },
    isDYTM() {
      //douyin tmall不需要轮播图
      let arr = ['DOUYIN', 'TMALL']
      return arr.includes(this.$store.state.currentShop.platform)
    },
    videoUpload() {
      let cpid = this.$store.getters.partyId
      return api.videoUpload + '?cpid=' + cpid
    },
    hasLog() {
      return this.goodsId && this.pageType !== 'copy'
    },
    isStore() {
      //是门店类型
      return this.$store.getters.isStore
    },
    mainHeight() {
      return (window.innerHeight - 370) * this.$store.state.rate + 'px'
    },
    hasFMSApp() {
      let currentIdaasApp = JSON.parse(localStorage.getItem('currentIdaasApp') || '{}')
      return !!currentIdaasApp['FMS']
    },
  },
  data() {
    return {
      plainOptions: [],
      buDefaultValue: [],
      is_changeBu: false,
      isChangeBu: false,
      goodsId: null,
      quickCreate: false,
      barName: '',
      pageType: '',
      formLayout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 20 },
      },
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
      },
      speed: {
        number: 0,
        isTrue: false,
        numberArr: [],
        sum: 6,
      },
      uploadImageUrlList: [],
      formData: {
        goodsId: undefined,
        goodsName: undefined,
        goodsCode: undefined,
        abbreviation: undefined,
        collectionName: undefined,
        goodsType: 'GENERAL',
        saleType: undefined,
        storageCondition: undefined,
        isMaterial: 0, //成品0 原料1
        categoryId: undefined,
        categoryName: undefined,
        businessUnitCode: undefined,
        businessUnitName: undefined,
        shortDescription: undefined,
        description: undefined,
        packageWeight: undefined,
        packageLength: undefined,
        packageWidth: undefined,
        packageHeight: undefined,
        packageContent: undefined,
        goodsImageList: [],
        skuList: [],
        brandName: undefined,
        taxRate: 100,
        shelfLife: null,
        shelfLifeUnit: undefined,
        needCustomize: undefined,
        nextSaleType: undefined,
        nextSaleTypeEffectiveDate: undefined,
        // videoUrl: "",
        // keyword: "",
        // deliveryDesc: "",
        // serviceDesc: "",
      },
      publishData: {
        shopCode: '',
        platform: '',
        goodsId: '',
        brand: '',
        goodsAttributes: {},
        skuList: [],
      },
      commitData: {
        onSaleTime: '',
        offSaleTime: '',
      },
      is_edit: false,
      is_updating: false,
      sku_list: [],
      // editorOption: {
      //   modules: {
      //     toolbar: {
      //       container: toolbarOptions, // 工具栏
      //       handlers: {
      //         image: (value) => {
      //           if (value) {
      //             this.operate_quill = 'shortDescription'
      //             document.getElementById('Upload').click()
      //           } else {
      //             this.quill.format('image', false)
      //           }
      //         },
      //       },
      //     },
      //   },
      // },
      // editorOption2: {
      //   modules: {
      //     toolbar: {
      //       container: toolbarOptions, // 工具栏
      //       handlers: {
      //         image: (value) => {
      //           if (value) {
      //             this.operate_quill = 'description'
      //             document.getElementById('Upload').click()
      //           } else {
      //             this.quill.format('image', false)
      //           }
      //         },
      //       },
      //     },
      //   },
      // },
      operate_quill: '',
      visiable: false,
      styleTypeList: null,
      imageDelId: [],
      publishAttributes: [],
      publishSkuList: [],
      category_text: '',
      brandNameOptions: [],
      operateLogList: [],
      videoUrl: '',
      loading: false,
      visibleVideo: false,
      unitList: [],
    }
  },
  methods: {
    playVideoHandle() {
      this.visibleVideo = true
    },
    beforeVideoUpload(file) {
      const isMp4 = file.type === 'video/mp4'
      if (!isMp4) {
        this.$message.error('You can only upload JPG file!')
      }
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('Image must smaller than 20MB!')
      }
      return isMp4 && isLt20M
    },
    handleVideoChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        this.videoUrl = info?.file?.response?.result?.url
        this.loading = false
      }
    },
    linkTo(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    beforeUpload() {
      this.is_updating = true
      return true
    },
    getbrandList() {
      http({
        instance: this,
        type: 'post',
        url: api.brandList,
        hasLoading: true,
        data: {},
        success: (res) => {
          this.brandNameOptions = res.result.brandList
        },
        error: () => {},
      })
    },
    handleChange() {},
    queryGoodsDetail() {
      http({
        instance: this,
        type: 'post',
        url: api.omsGoodsDetail,
        hasLoading: true,
        data: {
          goodsId: this.goodsId,
        },
        success: (res) => {
          this.buDefaultValue = res.result.goods.buIdList.map((item) => String(item))

          let goods_detail = res.result.goods
          goods_detail.taxRate = goods_detail.taxRate
            ? goods_detail.taxRate * 100
            : goods_detail.taxRate
          this.videoUrl = goods_detail.videoUrl
          this.unitList = goods_detail.unitList
          let arr = [...goods_detail.goodsImageList].map((item, index) => ({
            uid: '-' + (index + 1),
            name: `image${index}.png`,
            status: 'done',
            url: item.url,
            partyFileId: item.partyFileId,
          }))
          goods_detail.isMaterial *= 1
          goods_detail.needCustomize *= 1
          this.$set(this, 'uploadImageUrlList', arr)
          for (let item in this.formData) {
            this.$set(this.formData, item, goods_detail[item])
          }
          this.skuOperateLogPageList(goods_detail.skuList[0].skuCode)
          this.queryDefaultStyle(goods_detail.categoryId).then(() => {
            let goodsStyleList = goods_detail.goodsStyleList
            this.styleTypeList.forEach((item) => {
              let value_list = []
              goodsStyleList.forEach((elem, index) => {
                if (elem.styleType === item.styleType) {
                  value_list.push({
                    styleTypeId: index,
                    styleTypeName: elem.styleValue,
                    textAvailable: item.textAvailable,
                    uploadImageUrlList: [...elem.imageList].map((img_item, img_index) => ({
                      uid: '-' + (img_index + 1),
                      name: `image${img_index}.png`,
                      status: 'done',
                      url: img_item.url,
                      partyFileId: img_item.partyFileId,
                    })),
                  })
                }
              })
              this.$set(item, 'value_list', value_list)
            })
          })
        },
      })
    },
    queryDefaultStyle(categoryId) {
      return new Promise((resolve) => {
        http({
          instance: this,
          type: 'post',
          hasLoading: true,
          url: api.queryDefaultStyle,
          data: {
            categoryId,
          },
          success: (res) => {
            this.styleTypeList = res.result.styleTypeList
            resolve()
          },
        })
      })
    },
    handleCategoryChange(data) {
      if (data) {
        this.formData.categoryName = data.categoryName
        this.formData.categoryId = data.categoryId
        this.queryDefaultStyle(data.categoryId)
        this.queryPublishAttribute(data.categoryId)
      } else {
        this.formData.categoryName = ''
        this.formData.categoryId = ''
      }
      // this.$refs.skuChild.removeSku()
    },
    createCategory(data) {
      this.formData.categoryName = data.categoryName
      this.formData.categoryId = data.categoryId
      this.queryDefaultStyle(data.categoryId)
      this.queryPublishAttribute(data.categoryId)
    },
    saveDraft() {
      localStorage.setItem('isDraft', '1')
      localStorage.setItem('formData', JSON.stringify(this.formData))
      localStorage.setItem('publishData', JSON.stringify(this.publishData))
      localStorage.setItem('commitData', JSON.stringify(this.commitData))
      localStorage.setItem('uploadImageUrlList', JSON.stringify(this.uploadImageUrlList))
      this.$refs.skuChild.saveDraft()
      this.$nextTick(() => {
        this.$router.push({ name: 'products' })
      })
    },
    loadDraft() {
      let isDraft = localStorage.getItem('isDraft')
      if (isDraft) {
        Modal.confirm({
          title: 'Confirm',
          content: this.$t('发现草稿，是否读取？取消后自动清除草稿'),
          okText: this.$t('确认'),
          cancelText: this.$t('取消'),
          onOk: () => {
            let formData = localStorage.getItem('formData'),
              publishData = localStorage.getItem('publishData'),
              commitData = localStorage.getItem('commitData'),
              uploadImageUrlList = localStorage.getItem('uploadImageUrlList')
            if (formData) {
              this.formData = JSON.parse(formData)
              if (this.formData.categoryId) {
                this.handleCategoryChange({
                  categoryId: this.formData.categoryId,
                  categoryName: this.formData.categoryName,
                })
              }
            }
            if (publishData) {
              this.publishData = JSON.parse(publishData)
            }
            if (commitData) {
              this.commitData = JSON.parse(commitData)
            }
            if (uploadImageUrlList) {
              this.uploadImageUrlList = JSON.parse(uploadImageUrlList)
            }
            this.$refs.skuChild.loadDraft()
            this.$nextTick(() => {
              this.clearDraft()
            })
          },
          onCancel: () => {
            this.clearDraft()
          },
        })
      }
    },
    clearDraft() {
      setTimeout(() => {
        localStorage.removeItem('isDraft')
        localStorage.removeItem('formData')
        localStorage.removeItem('publishData')
        localStorage.removeItem('commitData')
        localStorage.removeItem('uploadImageUrlList')
        localStorage.removeItem('sku_item_list')
        localStorage.removeItem('tableData')
      }, 2000)
    },
    mathSum(type, key) {
      if (key == 'up') {
        this.speed.numberArr.indexOf(type) > -1 ? '' : this.speed.numberArr.push(type)
      } else {
        this.speed.numberArr.indexOf(type) > -1
          ? this.speed.numberArr.splice(this.speed.numberArr.indexOf(type), 1)
          : ''
      }
      this.speed.number = Math.floor((this.speed.numberArr.length / this.speed.sum) * 100)
    },
    confirmCreate(type) {
      let errmsg = ''
      if (type == 'speed') {
        // 来给suk setting 传入 控制错误提示 false 则不提示，
        this.speed.isTrue = true
      } else {
        this.speed.isTrue = false
      }
      // goodsName categoryName packageWeight packageLength
      if (this.is_updating) {
        if (type !== 'speed') {
          this.$warning(this.$t('上传图片中……'))
          return
        }
      }
      if (!this.formData.goodsName) {
        if (type == 'speed') {
          this.mathSum('goodsName', 'down')
        } else {
          this.$warning(this.$t('请输入商品名'))
          return
        }
      } else {
        this.mathSum('goodsName', 'up')
      }
      if (!this.formData.categoryName) {
        if (type === 'speed') {
          this.mathSum('categoryName', 'down')
        } else {
          this.$warning(this.$t('请选择商品类目'))
          return
        }
      } else {
        this.mathSum('categoryName', 'up')
      }
      if (this.isStore && !this.formData.shelfLife && type !== 'speed') {
        this.$warning(this.$t('请填写保质期'))
        return
      }
      if (this.formData.packageWeight === undefined) {
        if (type === 'speed') {
          this.mathSum('packageWeight', 'down')
        } else {
          this.$warning(this.$t('请输入重量'))
          return
        }
      } else {
        this.mathSum('packageWeight', 'up')
      }
      if (
        this.formData.packageLength === undefined ||
        this.formData.packageWidth === undefined ||
        this.formData.packageHeight === undefined
      ) {
        if (type === 'speed') {
          this.mathSum('packageLength', 'down')
        } else {
          this.$warning(this.$t('请输入尺寸'))
          return
        }
      } else {
        this.mathSum('packageLength', 'up')
      }
      if (type !== 'speed' && this.buDefaultValue.length === 0) {
        return this.$warning(this.$t('至少选择一个业务组'))
      }
      this.$nextTick(() => {
        this.$refs.skuChild.submitData().then(({ styleTypeList, sku_list }) => {
          if (sku_list.length === 1 && sku_list[0].styleType === '') {
            let sku_item = sku_list[0]
            //无规格
            this.formData.skuCode = sku_item.skuCode
            this.formData.barcode = sku_item.barcode
            this.formData.brandSku = sku_item.brandSku
            this.formData.compositionOrMaterial = sku_item.compositionOrMaterial
            this.formData.supplierSku = sku_item.supplierSku
            this.formData.price = sku_item.price
            this.formData.quantity = sku_item.quantity
            this.formData.unit = sku_item.unit
            this.formData.sampleQuantity = sku_item.sampleQuantity
            if (type == 'speed') {
              if (this.formData.skuCode && this.formData.barcode && this.formData.price) {
                this.mathSum('skuList', 'up')
              } else {
                this.mathSum('skuList', 'down')
              }
            }
            if (this.formData.skuList.length) {
              this.formData.skuList[0].skuName = this.formData.goodsName
              this.formData.skuList[0].skuStyleList = []
              this.formData.skuList[0].styleDesc = ''
              this.formData.skuList[0].styleType = ''
              this.formData.skuList[0].styleTypeName = ''
            }
          } else {
            //有规格
            sku_list.forEach((sku_item) => {
              let styleTypeNameList = sku_item.styleTypeName.split('\\')
              let skuStyleList = sku_item.styleType.split('\\').map((item, index) => ({
                styleType: item,
                styleValue: styleTypeNameList[index],
              }))
              sku_item.skuStyleList = skuStyleList
            })
            this.formData.skuList = sku_list
            if (type == 'speed') {
              this.mathSum('skuList', 'up')
            }
          }
          let goodsStyleList = []
          styleTypeList.forEach((item) => {
            item.value_list.forEach((elem) => {
              let obj = {
                styleType: item.styleType,
                styleValue: elem.styleTypeName,
              }
              if (elem.uploadImageUrlList.length) {
                obj.imageList = elem.uploadImageUrlList.map((image_item, idx) => ({
                  partyFileId: image_item.partyFileId,
                  idx,
                }))
              }
              goodsStyleList.push(obj)
            })
          })
          if (!this.isDYTM && this.uploadImageUrlList.length === 0) {
            errmsg = this.$t('商品图必填，尺寸在500×500到2000×2000px之间')
          }
          if (errmsg) {
            if (type === 'speed') {
              this.mathSum('BannerRequired', 'down')
            } else {
              this.$warning(errmsg)
              return
            }
          } else {
            this.mathSum('BannerRequired', 'up')
          }
          if (this.quickCreate) {
            //发布信息
            this.publishAttributes.forEach((item) => {
              if (item.isMandatory && !this.publishData.goodsAttributes[item.name]) {
                errmsg = `${item.name}${this.$t('必填')}`
                if (type === 'speed') {
                  this.mathSum(item.name, 'down')
                }
              } else if (item.isMandatory && this.publishData.goodsAttributes[item.name]) {
                if (type === 'speed') {
                  this.mathSum(item.name, 'up')
                }
              }
            })
            if (errmsg) {
              if (type != 'speed') {
                this.$warning(errmsg)
                return
              }
            }
            //审核信息
            if (!this.commitData.onSaleTime) {
              if (type === 'speed') {
                this.mathSum('onSaleTime', 'down')
              } else {
                this.$warning(this.$t(`开始时间必填`))
                return
              }
            } else {
              if (type === 'speed') {
                this.mathSum('onSaleTime', 'up')
              }
            }
          }
          if (type != 'speed') {
            this.createOmsGoods(goodsStyleList)
          }
        })
      })
    },
    createOmsGoods(goodsStyleList) {
      let submitFormData = deepClone(this.formData)
      console.log('this.formData: ', this.formData)
      if (!this.plainOptions.length) return
      if (this.isStore) {
        if (submitFormData.needCustomize === undefined) {
          this.$warning(this.$t('请选择是否需要客制'))
          return
        }
        submitFormData.needCustomize = Boolean(submitFormData.needCustomize)
        if (!submitFormData.saleType) {
          this.$warning(this.$t('请选择销售类型'))
          return
        }
        if (!submitFormData.storageCondition) {
          this.$warning(this.$t('请选择存储方式'))
          return
        }
      } else {
        submitFormData.saleType = 'PURCHASE_SALE' //电商销售类型默认采购销售
        submitFormData.storageCondition = 'NORMAL_TEMPERATURE' //电商存储方式默认常温
      }
      let goodsImageList = this.uploadImageUrlList
        .filter((item) => item.partyFileId)
        .map((item, idx) => ({
          partyFileId: item.partyFileId,
          idx,
        }))
      let buIdList = this.buDefaultValue.filter((item) => item) //过滤掉null和""
      submitFormData.goodsImageList = goodsImageList
      submitFormData.goodsStyleList = goodsStyleList
      submitFormData.videoUrl = this.videoUrl
      submitFormData.unitList = this.unitList

      if (submitFormData.skuList.length === 1 && submitFormData.skuList[0].styleType === '') {
        submitFormData.skuList[0].skuImageList = goodsImageList
      }
      let url =
        this.pageType === 'edit'
          ? api.omsGoodsUpdate
          : this.quickCreate
          ? api.omsGoodsCreateQuick
          : api.omsGoodsCreate
      let data = this.quickCreate
        ? {
            createGoodsRequest: submitFormData,
            publishGoodsRequest: this.publishData,
            commitSkuRequest: this.commitData,
            updateBu: this.is_changeBu,
            buIdList,
          }
        : { ...submitFormData, updateBu: this.is_changeBu, buIdList: this.buDefaultValue }

      data.taxRate = data.taxRate / 100
      if (this.$route.query.type === 'copy') {
        delete data.goodsId
      }
      console.log('请求参数', data)
      http({
        instance: this,
        type: 'post',
        url,
        hasLoading: true,
        data,
        success: () => {
          this.$success(this.pageType === 'edit' ? this.$t('编辑成功') : this.$t('创建成功'))
          this.$nextTick(() => {
            if (this.pageType === 'edit') {
              this.is_edit = false
              this.skuOperateLogPageList(submitFormData.skuList[0].skuCode)
            } else {
              this.clearFormData()
              this.$refs.skuChild.removeSku()
              localStorage.setItem('refresh', 1)
              this.$router.go(-1)
            }
          })
        },
      })
    },
    clearFormData() {
      Object.assign(this.$data, this.$options.data())
    },
    goToList() {
      // this.$router.push({ name: "products" });
      this.$router.back()
    },
    handleUploadChange({ file }) {
      // 获取富文本组件实例
      this.is_updating = false
      let quill = this.$refs[this.operate_quill].quill
      if (file) {
        this.$nextTick(() => {
          // 如果上传成功
          if (file.response) {
            let url = file.response.result.url
            // 获取光标所在位置
            let length = quill.getSelection().index
            // 插入图片，res为服务器返回的图片链接地址
            quill.insertEmbed(length, 'image', url)
            // 调整光标到最后
            quill.setSelection(length + 1)
          }
        })
      }
    },
    getDelImageId(list) {
      this.uploadImageUrlList = [...list]
    },
    //PublishInformation
    queryPublishAttribute(categoryId) {
      if (!this.quickCreate) return
      http({
        instance: this,
        type: 'post',
        url: api.queryPublishAttribute,
        data: {
          categoryId: categoryId,
          shopCode: this.publishData.shopCode,
          platform: this.publishData.platform,
        },
        hasLoading: true,
        success: (res) => {
          this.category_text = res.result.categoryChain
          this.publishAttributes = res.result.attributes
          this.speed.sum = 6
          this.publishAttributes.forEach((item) => {
            if (item.isMandatory) {
              this.speed.sum++
            }
          })
          if (this.quickCreate && this.publishAttributes.length > 0) {
            this.speed.sum++
          }
          let skuList = res.result.skuList
          skuList.forEach((item) => {
            item.styles.forEach((elem) => {
              item[elem.styleType] = elem.styleValue
            })
          })
          this.publishSkuList = skuList
        },
      })
    },
    onSelectGoodsBrand(data) {
      if (data) {
        this.publishData.brand = data
      } else {
        this.publishData.brand = ''
      }
    },
    // CommitInformation
    getOnSaleTime(date) {
      this.commitData.onSaleTime = date
    },
    getOffSaleTime(date) {
      this.commitData.offSaleTime = date
    },
    skuOperateLogPageList(value) {
      http({
        instance: this,
        type: 'post',
        url: api.skuOperateLogPageList,
        hasLoading: true,
        data: {
          skuCode: value,
          pageSize: 200,
          pageNo: 1,
        },
        success: (res) => {
          this.operateLogList = res.result.operateLogList
        },
      })
    },
    onChange(checkedValues) {
      this.buDefaultValue = checkedValues
      this.is_changeBu = true
    },
    selectedShop(data) {
      this.publishData.shopCode = data.code
      this.publishData.platform = data.platform
    },
    changeGoodsType(val) {
      // this.unitList = []
      if (val === 'RAW_MATERIAL') {
        this.formData.needCustomize = 0
      }
    },
    getNextSaleTime(date) {
      this.formData.nextSaleTypeEffectiveDate = date
    },
    handleInput: debounce(function (event) {
      const value = event.target.value
      const regex = /^[a-zA-Z0-9-]*$/ // 允许的字符：数字和英文字母
      if (!regex.test(value)) {
        // 如果输入的字符不符合要求，则从字符串中移除不合法的字符
        this.formData.goodsCode = value.replace(/[^a-zA-Z0-9-]/g, '')
      } else {
        this.formData.goodsCode = value
      }
    }),
  },
}
</script>

<style lang="scss" scoped>
.header-btn {
  padding-top: 10px;
  padding-left: 6px;
}
.title {
  font-size: 18px;
  margin-bottom: 20px;
}
.image-list {
  .tips {
    font-size: 12px;
    color: $font-color-light;
  }
}
.key-icon {
  font-size: 10px;
  padding: 2px 6px;
  margin-right: 6px;
  border-radius: 4px;
  background-color: $warning-color;
  color: $white;
}
.ql-toolbar.ql-snow {
  line-height: normal;
}
.ql-editor {
  min-height: 100px;
}
.hide {
  visibility: hidden;
  height: 0;
  opacity: 0;
  position: absolute;
  top: -100%;
  left: -100%;
}
.goods-main-content {
  //height: calc(100vh - 315px);
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  background-color: #f0f2f5;
  #BasicInfo {
    width: 100%;
    &.hasLog {
      width: calc(100% - 250px);
    }
  }
  .rightBox {
    width: 100%;
    height: auto;
    margin-left: 10px;
    color: #333333;
  }
}
.content-wrapper {
  min-height: 0 !important;
}
::v-deep .ant-form-item {
  margin: 3px 0;
}
.content {
  padding: 0 !important;
}
.formContentGoods {
  padding: 10px 15px;
  margin-bottom: 10px;
  background-color: white;
}
.titleFormBox {
  min-height: 0;
  padding: 9px 21px 9px 21px !important;
}
.bottomFormBox {
  min-height: 0;
  padding: 21px !important;
}
.videolist {
  width: 102px;
  height: 102px;
  display: inline-block;
  position: relative;
  margin-right: 20px;
  border-radius: 6px;
  overflow: hidden;
  transition: opacity 0.3s;
  img,
  video {
    width: 100%;
    height: 100%;
  }
  span {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: $white;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.videolist:hover span {
  opacity: 1;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
}
.goodsTips {
  position: absolute;
  right: -20px;
  top: 3px;
  z-index: 8;
}
</style>
